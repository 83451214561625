<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="4">
								Semua Status Permohonan
							</CCol>
							<CCol md="8" class="text-right">
								<router-link :to="{path: 'add-status-permohonan'}" class="btn btn-success btn-sm"><i class="fa fa-plus"></i> Tambah</router-link>
							</CCol>
						</CRow>
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol sm="12">
								<CRow>
									<CCol sm="12">
										<CRow class="my-2 mx-0">
											<CCol sm="6" class="form-inline p-0">
												<label data-v-1c2db5c2="" class="mr-2">Filter:</label>
												<input type="text" class="form-control" id="search-table" placeholder="Search.." v-on:change="searchMaster" v-model="searchData" />
											</CCol>
											<CCol sm="6" class="p-0 text-right">
												Show {{show_data_from}}-{{show_data_to}} From {{total_data}}
											</CCol>
										</CRow>
										<table class="table table-sm table-bordered">
											<thead class="bg-dark text-white">
												<tr>
													<th>No</th>
													<th>Nama Status</th>
													<th>Kategori Status</th>
													<th>Urutan</th>
													<th>Status</th>
													<th>Aksi</th>
												</tr>
											</thead>
											<tbody>
												<template>
                                                    <tr v-if="loadingTable">
                                                        <td colspan="6"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                    </tr>
													<tr v-for="(row_data, key_satuan) in dataintable" :key="key_satuan" v-else>
														<td>
                                                            <template>
                                                                <div v-if="key_satuan+1 == 10">
                                                                    {{curent_page}}0
                                                                </div>
                                                                <div v-else>
                                                                    {{(curent_page-1 != 0)?curent_page-1:''}}{{key_satuan+1}}
                                                                </div>
                                                            </template>
                                                        </td>
														<td>{{row_data.st_nama}}</td>
														<td>{{row_data.st_kategori}}</td>
														<td>{{row_data.st_urutan}}</td>
														
														<td>
															<template>
				                                                <CBadge :color="getBadge(row_data.status)">
																	<div v-if="row_data.status">
																		Enable
																	</div>
																	<div v-else>
																		Disable
																	</div>
			                                                    </CBadge>
				                                            </template>
														</td>
														<td>
															<template>
                                                                <button v-if="row_data.status" class="btn btn-danger btn-sm" v-on:click="deleteData(row_data.id)"><i class="fa fa-trash"></i> Delete</button>
                                                                <button v-else class="btn btn-warning btn-sm" v-on:click="restoreData(row_data.id)"><i class="fa fa-refresh"></i> Restore</button>
                                                            </template>
                                                            <router-link :to="{name: 'EditStatusPermohonan', params: {idStatus: row_data.id}}" class="btn btn-info btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
														</td>
													</tr>
												</template>
											</tbody>
										</table>
										<nav aria-label="pagination">
										    <ul class="pagination justify-content-start">
										        <!---->
										        <!-- {{ page.active ? 'active' : '' }} -->
										        <li v-for="(page, key_page) in paging" v-bind:class="{ 'active': page.active }" :key="key_page" class="page-item">
										        	<div v-on:click="toPage(page.url)" class="page-link c-page-link-number"> 
											        	<div v-html="page.label"></div>
											        </div>
										        </li>
										    </ul>
										</nav>
									</CCol>
								</CRow>
							</CCol>
						</CRow>
						<br />
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	const dataintable = [];
	export default {
		name: "AllStatusPermohonan",
		components: {},
		data() {
			return {
		    	dataintable: [],
		    	paging: [],
		    	show_data_to: '',
		    	show_data_from: '',
				total_data: '',
				curent_page: '',
                loadingTable: true,
		    	searchData: ''
            }
        },
        methods: {
            restoreData(id){
                // alert(id);
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda akan me-restore data tersebut.",
                    showDenyButton: false,
                    showCancelButton: true,
					confirmButtonClass: `btn btn-warning`,
                    confirmButtonText: `Proses`,
					cancelButtonClass: `btn btn-default`,
                    cancelButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Loading...",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/status_permohonan", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data berhasil dikembalikan.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            this.searchMaster();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
			searchMaster(){
                this.loadingTable = true;
                axios
                .get(this.apiLink + "api/crud/status_permohonan", {
                    params: {
                        search: this.searchData,
                    },
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
					var res_satuan = response.data;
					var res_satuan_data = res_satuan.data[0];
					// console.log(res_satuan_data);
					if (res_satuan.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_satuan.data.message
						});
					}
					else{
						this.loadingTable = false;
						this.dataintable = res_satuan_data.data;
						this.paging = res_satuan_data.links;
						this.show_data_from = res_satuan_data.from;
						this.show_data_to = res_satuan_data.to;
						this.curent_page = res_satuan_data.current_page;
					}
				});
            },
			toPage(url) {
				this.loadingTable = true;
				// var numpage = url.replace(this.apiLink+'api/crud/status_permohonan?page=','');
				axios.get(url, {
					// params: {
					// 	page: numpage,
					// },
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
				})
				.then((response) => {
					var res_satuan = response.data;
					var res_satuan_data = res_satuan.data[0];
					// console.log(res_satuan_data);
					if (res_satuan.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_satuan.data.message
						});
					}
					else{
						this.loadingTable = false;
						this.dataintable = res_satuan_data.data;
						this.paging = res_satuan_data.links;
						this.show_data_from = res_satuan_data.from;
						this.show_data_to = res_satuan_data.to;
						this.curent_page = res_satuan_data.current_page;
					}
				});
			},
			color(value) {
				let $color;
				if (value <= 25) {
					$color = "info";
				} else if (value > 25 && value <= 50) {
					$color = "success";
				} else if (value > 50 && value <= 75) {
					$color = "warning";
				} else if (value > 75 && value <= 100) {
					$color = "danger";
				}
				return $color;
			},
			getBadge(status) {
				if (status) {
					return "success";
				}
				else{
					return "danger";
				}
			},
			deleteData(id) {
                // alert(id);
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda tidak bisa mengembalikan data yang dihapus!",
                    showDenyButton: false,
                    showCancelButton: true,
					confirmButtonClass: `btn btn-danger`,
                    confirmButtonText: `Proses`,
					cancelButtonClass: `btn btn-default`,
                    cancelButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Loading...",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/status_permohonan", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                // console.log(res_data);
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data berhasil dihapus.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
		},
		beforeCreate() {
			axios.get(this.apiLink + "api/crud/status_permohonan", {
				headers: {
					Authorization: "Bearer " + this.access_token,
				},
			})
			.then((response) => {
				var res_satuan = response.data;
				var res_satuan_data = res_satuan.data[0];
				// console.log(res_satuan_data);
				if (res_satuan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_satuan.data.message
					});
				}
				else{
					this.loadingTable = false;
					this.dataintable = res_satuan_data.data;
					this.paging = res_satuan_data.links;
					this.show_data_from = res_satuan_data.from;
					this.show_data_to = res_satuan_data.to;
					this.total_data = res_satuan_data.total;
					this.curent_page = res_satuan_data.current_page;
				}
			});
		},
	};
</script>